<template>
  <!-- 现任领导详情 -->
  <div class="container" style="padding-top: 0.7rem;">
    <div class="detail-index-bg">
      <div class="detail-index-bottom clear">
          <Crumbs/>
        <!-- <div class="detail-index-bottom-bread" @click="goBack()">
        <span  style="cursor:pointer;color:black;margin-right:0.1rem">工作年报</span>/
          <span style="margin-left:0.1rem">{{ info.title }}</span>
        </div> -->
        <div class="detail-index-bottom-left" style="position:relative;">
          <img
            src="https://image.bookgo.com.cn/webculture/jm/border-top.png"
            alt=""
            class="border-top"
            style="position:absolute;top:0;left:0;width:100%"
          />
          <div
            class="detail-index-bottom-left-bottom-summary"
            v-html="info.content"
          >
            {{ info.content }}
          </div>

          <img
            src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
            alt=""
            class="border-bottom"
            style="position:absolute;bottom:0;left:0;width:100%"
          />
        </div>
      </div>
      <div class="downFile-all" v-if="attachsArray.length > 0">
        <div class="file-title">
          <img style="vertical:middle" src="https://image.bookgo.com.cn/%20webculture/jm/fujianjinmen.png" />
          <span style="margin-left:0.05rem;vertical-align:middle">附件</span>
        </div>
        <div class="file-list" v-for="(item,i) in attachsArray" v-bind:key="i">          
          <span>{{item.name}}</span>
          <a :href=item.url style="display: inline-block;"> 
            <span class="downButton">下载</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { info as articleinfo, list } from "@/api/article";
import Crumbs from "@/views/components/crumbs";
export default {
    components:{Crumbs},
  data() {
    return {
      id: null,
      pid: "",
      info: {},
      detailType: "news",
      news_team_list: [
        {
          cover: "",
          title: " 武穴市歌声悠扬艺术团",
          summary: "2020-00-00至2020-00-00",
        },
        {
          cover: "",
          title: " 武穴市公共文化志愿者团队",
          summary:
            "阿胶是东方科技大厦能否看见的是苦涩的烦恼都是看法你打开手机菲尼克斯的烦恼上岛咖啡的时刻开始的菲尼克斯觉得你烦开始的你发开始大家菲尼克斯地方是看到你发就开始的你发开始的你发",
        },
        {
          cover: "",
          title: " 武穴市公共文化志愿者团队",
          summary: "60",
        },
      ],
      attachsArray:[],
      tid:"",
    };
  },
  created() {},
  mounted() {
    document.documentElement.scrollTop = 0;
    // window.scroll(0, 0);
    this.tid = this.$route.query.tid;
    this.getInfo();
  },
  watch: {
    $route(newV) {
      document.documentElement.scrollTop = 0;
      this.tid = newV.query.tid;
      this.getInfo()
    },
  },
  methods: {
    handleClosed() {},
    getInfo() {
      articleinfo({ id: this.tid }).then((res) => {
          if(typeof(res.data.article.attachs)=="object"){
              var attachs = JSON.parse(res.data.article.attachs)
        this.attachsArray = attachs
          }else if(typeof(res.data.article.attachs)=="string"){
this.attachsArray = [];
          }
        
        const regex = new RegExp("<img", "gi");
        res.data.article.content = res.data.article.content.replace(
          regex,
          `<img style="max-width: 100%; height: auto;margin:0 auto"`
        );
        this.info = res.data.article;
        console.info(this.info);
      });
    },
    goBack() {
            let routeUrl = this.$router.resolve({
        path: `/profile/workreport`,
        query: { id:this.$route.query.pid,cat_id:this.$route.query.cat_id,navId:this.$route.query.navId,name:this.$route.query.name},
      });
      window.location = routeUrl.href
    }
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/detail.scss";
</style>
